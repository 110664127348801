import * as React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Link } from 'components/Html';
import CheckIcon from 'assets/icons/check.svg';
import { trailingSlashIt } from 'utils/url';
import cn from 'classnames';

export default function LanguageSwitcher({ menuItems = [] }) {
	const router = useRouter();

	if (!menuItems.length) {
		return null;
	}

	return (
		<div className="language-switcher">
			{menuItems.map((region) => {
				const sites = Array.isArray(region.sites) ? region.sites : [];
				const isActiveLocale = sites.some((z) => {
					return z.locale === router.locale;
				});

				return (
					<div
						key={region.id}
						className={`region-select ${isActiveLocale ? 'region-select--active' : ''}`}
					>
						<div className="region-select__name">{`${region.name}:`}</div>

						{region?.sites?.length > 0 ? (
							<div className="region-select__sites">
								{region.sites.map((site) => {
									const isActive = site.locale === router.locale;
									// Using <a> instead of <Link> because we want to make sure we do not accidentally
									// land on stories.starbucks.com. This may happen if we use <Link /> and clicking
									// from sites like stories.starbucks.com/emea to stories.starbucks.com. Link will
									// take over the browser behavior and allow users to browse the stories.starbucks.com
									// site which is not what we want.
									return (
										<div
											className={cn('region-select__site-name', {
												'region-select__site-name--active': isActive,
											})}
											key={site.locale}
										>
											<a
													href={trailingSlashIt(site.domain)}
													hrefLang={site.hreflang}
													className={cn('region-select__site-name', {
															'region-select__site_link--active': isActive,
													})}
											>
													{site.name}
													{site.name === 'English' && (
															<span className="screen-reader-text">{` ${site.region.name}`}</span>
													)}
													{isActive && (
															<span className="region-select__selected">
																	<CheckIcon />
															</span>
													)}
											</a>
										</div>
									);
								})}
							</div>
						) : null}
					</div>
				);
			})}
		</div>
	);
}

LanguageSwitcher.propTypes = {
	menuItems: PropTypes.arrayOf(PropTypes.object),
};
